import React from 'react'

import Slider from 'react-slick'

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: 'Brain Hanrahan',
      position: 'Senior Director of Product, Remediant',
      src: 'images/testimonial/brian-1.webp',
      desc: 'It was a sincere pleasure to collaborate with Frank for 3 years at Remediant as our Head of Engineering while I was Head of Product. Frank is a remarkably talented, adaptable and hard-working professional technical leader. His unique combination of broad experience, team leadership, technical excellence and recognition of customer value made him an ideal partner and trusted advisor to myself, the Customer Success organization and executive leadership. As a leader, Frank deployed his team effectively to meet the concurrent demands of customer support, enhancements and product expansion.His team delivered work at a substantially increasing pace over his tenure due to the improvements in process and focus under his leadership. He continually improved tools and processes to improve SDLC, productivity and compliance and was instrumental in achieving our SOC2 accreditation. Frank delegated effectively, allowing team members to grow as leaders as well as in their technical abilities. Although Franks primary role was leadership, he personally executed on key architectural designs and implementations to ensure projects progressed and completed on time, or to work past blocking issues. Frank has experience working with executives, product, marketing, customer success and sales made him an important contributor to organizational leadership and planning. Experience in product management and product owner roles allowed Frank to work very effectively in designing phased delivery of complicated initiatives such as a SaaS. Frank was comfortable and more than able to present and speak to our product roadmap and commercial drivers in any audience. Frank has a phenomenal work ethic - he always goes the extra mile for his colleagues and customers. Frank always has your back. I am anxious to see what Frank decides to do next!',
    },
    {
      name: 'Paul Lanzi',
      position: 'COO, Remediant',
      src: 'images/testimonial/paul-1.webp',
      desc: 'Head of Engineering at Series A startup is a tough gig. You have got the chaos inherent at a company that sized, but the outsize expectations of (technical) founders who want the entire product roadmap delivered, preferably immediately. Sanding off the rough edges of a product, while building and running a diverse and motivated engineering team -- and, oh by the way, standing up a QA team from scratch -- it all makes for a pretty tall order. Lucky for us, that was an order that Frank Dye was ready to fill. It has been an absolute pleasure working with Frank as he coached the engineering team at Remediant to develop new features, faster, and with higher quality. I greatly enjoyed the partnership we created between his engineering team and the Customer Success team -- to the great effect of meeting customer needs more quickly and accurately. Besides being a great Head of Engineering, Frank was also a highly valued member of the SLT (Senior Leadership Team) at Remediant, regularly participating in strategic conversations about the industry, company direction and more. I personally recommend Frank for roles that call on his organizational, people and creative skills to fill leadership roles and address both engineering and team challenges.',
    },
    {
      name: 'Jianpeng Mo',
      position: 'Director of Engineering, OPSWAT',
      src: 'images/testimonial/jp-1.webp',
      desc: 'Frank is one of the most talented engineering leaders that I have worked with. He has extremely outstanding hands on skill, he is capable to lead the engineering team to put together high quality products, and he also cares about his team, supporting his team to learn and growth both as individual and team as a whole. He is a great fast learner and able to stand into a new area, quickly pick up technology and deliver the impact.',
    },
    {
      name: 'Tomas Hertus',
      position: 'Team Lead, OPSWAT',
      src: 'images/testimonial/thertus-1.webp',
      desc: 'Frank is intelligent, likable, and always professional. He is a talented engineering lead who approaches problems pragmatically, focusing on the results and is able to properly utilize the skills of his teams. OPSWAT can be a very demanding environment and Frank has been successful in delivering high quality products and services, quarter by quarter with high precision and success. I consider Frank to be very customer focused and will think about customers at first place. It was a pleasure to work alongside Frank.',
    },
  ]

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }
  return (
    <section
      id="testimonial"
      className={'section ' + (darkTheme ? 'bg-dark-1' : '')}
    >
      <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              'text-24  text-uppercase fw-600 w-100 mb-0 ' +
              (darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4')
            }
          >
            Testimonial
          </h2>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    ' rounded p-5 ' + (darkTheme ? 'bg-dark' : 'bg-light')
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          'd-block fw-600 ' +
                          (darkTheme ? 'text-white' : 'text-dark')
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {' '}
                        {value.position}{' '}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      ' fw-500 mb-4 ' + (darkTheme ? 'text-white' : 'text-dark')
                    }
                  >
                    “{value.desc}”
                  </p>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  )
}

export default Testimonials
