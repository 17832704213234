import React from 'react'
import resumeFile from '../documents/FrankDyeResume-3-3-23.pdf'

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: '2013 - 2015',
      title: 'Bachelor of Science, Computer Science',
      place: 'San Francisco State University',
      desc: ['IEEE Student Member', 'ACM Student Member'],
    },
    {
      yearRange: '2011 - 2013',
      title: 'Transfer, Computer Science',
      place: 'City College of San Francisco',
      desc: [
        'MESA Scholar',
        'Advanced Studies through CCSF-UC Berkeley Concurrent Enrollment Program (Fall 2012)',
        'IEEE Student Member',
      ],
    },
  ]

  const experienceDetails = [
    {
      yearRange: 'Nov. 2020 - Dec. 2022',
      title: 'Head of Engineering',
      place: 'Remediant, now a part of Netwrix',
      desc: [
        'Led Remediant engineering to substantially increase output which ultimately resulted in acquisition by Netwrix in Dec. 2022',
        'Responsible for managing software development projects and setting requirements, goals, and timelines',
        'Grew engineering team size by 3x in addition to establishing Quality Assurance and DevOps groups',
        'Launched SaaS version of the SecureONE PAM product',
        'Reduced average engineering headcount spend by 35%',
        "Ensured compliance with security regulations and facilitated the company's SOC2 certification",
        'Prioritized diversity and inclusivity in the hiring process',
      ],
    },
    {
      yearRange: 'Feb. 2019 - Nov. 2020',
      title: 'Director of Engineering',
      place: 'OPSWAT',
      desc: [
        'Oversaw Cross-Domain product group, including MetaDefender Kiosk, MetaDefender Vault, and MetaDefender Drive',
        'Chief architect on new products in addition to preparing timelines & roadmaps, and staying within budget',
        'Supervised 13 team members across the Bay Area and Southeast Asia',
        'Designed, developed, launched MetaDefender Drive, and achieved six figure revenue target in 2019',
        'On track for 300% revenue growth of MetaDefender Drive in 2020',
      ],
    },
    {
      yearRange: 'Jan. 2018 - Feb. 2019',
      title: 'Software Engineering Team Lead',
      place: 'OPSWAT',
      desc: [
        'Team Lead for MetaDefender Kiosk, #2 revenue contributor in company. MetaDefender Kiosk is deployed in 95% of Nuclear Power Plants in the US',
        'Launched MetaDefender Kiosk hardware line to better facilitate sales of Kiosk software',
        'Achieved 99.5% customer satisfaction rating',
        'Worked closely with sales and customers to grow top line revenue 261% from 2018 to 2019',
      ],
    },
    {
      yearRange: 'May 2015 - Jan. 2018',
      title: 'Software Engineer',
      place: 'OPSWAT',
      desc: [
        'Full-stack web application developer for MetaAccess SaaS solution, specializing in access control, policy check, and compliance',
        'Updated legacy monolithic code base to take advantage of micro-service architecture',
        'Worked closely with DevOps to increase redundancy and lower costs on AWS',
        'Developed integration test suite for REST API per specifications',
      ],
    },
    {
      yearRange: 'Dec. 2014 - May 2015',
      title: 'Software Engineering Intern',
      place: 'OPSWAT',
      desc: [
        'Assisted with design and implementation of next generation REST API for web-based endpoint security',
        'Full stack development with Spring Framework and Node.js, CSS, JavaScript, MongoDB',
        'Developed integration test suite for REST API per specifications',
      ],
    },
    {
      yearRange: 'June 2014 - Aug. 2014',
      title: 'Intern',
      place: 'AT&T',
      desc: [
        'Advised leadership on product development roadmap for emerging WebRTC standard',
        'Planned and executed demo and SDK development',
        'Compiled extensive competitive research on WebRTC marketplace',
        'Created financial models to estimate WebRTC market size',
      ],
    },
  ]

  const patentDetails = [
    {
      yearRange: 'Granted Dec. 6, 2022',
      title: 'COMPUTER SECURITY VULNERABILITY',
      link: 'https://patents.google.com/patent/US11522901B2/en?oq=11522901',
      patentNumber: 'US 11522901',
      desc: 'A method to tie specific binaries against a known vulnerability database to best case determine its country of origin. This was done to facilitate executive directives to inventory software systems running within US critical infrastructure. This was an extension of an existing OPSWAT patent for matching known vulnerabilities to binary hashes.',
    },
  ]

  const skills = [
    {
      name: 'Engineering Management',
      percent: 85,
    },
    {
      name: 'System Architecture',
      percent: 85,
    },
    {
      name: 'Software Engineering',
      percent: 99,
    },
    {
      name: 'Compliance',
      percent: 75,
    },
    {
      name: 'Project Management',
      percent: 75,
    },
    {
      name: 'HTML/CSS',
      percent: 95,
    },
    {
      name: 'JavaScript',
      percent: 80,
    },
    {
      name: 'NodeJS',
      percent: 80,
    },
    {
      name: 'React',
      percent: 70,
    },
    {
      name: 'Angular',
      percent: 60,
    },
    {
      name: 'C/C++',
      percent: 85,
    },
    {
      name: 'Python',
      percent: 65,
    },
    {
      name: 'AWS',
      percent: 70,
    },
  ]

  let educationKeyIter = 0
  let experienceKeyIter = 0

  return (
    <section
      id="resume"
      className={'section ' + (darkTheme ? 'bg-dark-1' : '')}
    >
      <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              'text-24  text-uppercase fw-600 w-100 mb-0 ' +
              (darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4')
            }
          >
            Resume
          </h2>
        </div>
        {/* Heading end*/}

        <div className="row gx-5">
          {/* My Experience */}
          <div className="col-md-12">
            <h2
              className={
                'text-6 fw-600 mb-4 ' + (darkTheme ? 'text-white' : '')
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    'bg-white  rounded p-4 mb-4 ' +
                    (darkTheme ? 'bg-dark' : 'bg-white border')
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={'text-5 ' + (darkTheme ? 'text-white' : '')}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? 'text-primary' : 'text-danger'}>
                    {value.place}
                  </p>
                  <ul>
                    {value.desc.map(lineItem => (
                      <li key={experienceKeyIter++}>{lineItem}</li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>

        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-12">
            <h2
              className={
                'text-6 fw-600 mb-4 ' + (darkTheme ? 'text-white' : '')
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    'bg-white  rounded p-4 mb-4 ' +
                    (darkTheme ? 'bg-dark' : 'bg-white border')
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={'text-5 ' + (darkTheme ? 'text-white' : '')}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? 'text-primary' : 'text-danger'}>
                    {value.place}
                  </p>
                  <ul>
                    {value.desc.map(lineItem => (
                      <li key={educationKeyIter++}>{lineItem}</li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>

        {/* Patents */}
        <div className="row gx-5">
          <div className="col-md-12">
            <h2
              className={
                'text-6 fw-600 mb-4 ' + (darkTheme ? 'text-white' : '')
              }
            >
              Patents
            </h2>
            {patentDetails.length > 0 &&
              patentDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    'bg-white  rounded p-4 mb-4 ' +
                    (darkTheme ? 'bg-dark' : 'bg-white border')
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={'text-5 ' + (darkTheme ? 'text-white' : '')}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? 'text-primary' : 'text-danger'}>
                    {value.patentNumber}
                  </p>
                  <p>{value.desc}</p>
                </div>
              ))}
          </div>
        </div>

        {/* My Skills */}
        <h2
          className={
            'text-6 fw-600 mt-4 mb-4 ' + (darkTheme ? 'text-white' : '')
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    ' fw-500 text-start mb-2 ' +
                    (darkTheme ? 'text-light' : 'text-dark')
                  }
                >
                  {skill.name}{' '}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    'progress progress-sm mb-4 ' + (darkTheme ? 'bg-dark' : '')
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + '%' }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    aria-label="Progress bar"
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download Resume
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Resume
