import React, { useEffect, useRef, useState } from 'react'
import Isotope from 'isotope-layout'
import ProjectDetailsModal from './ProjectDetailsModal.jsx'
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef()
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState('*')
  const [imagesLoaded, setimagesLoaded] = useState(0)
  const [selectedProjectDetails, setSelectedProjectDetails] = useState()

  const filters = {
    CYBERSECURITY: 'CyberSecurity',
  }

  const projectsData = [
    {
      title: 'SecureONE',
      projectInfo:
        'Remediant SecureONE is a first-of-its-kind agentless Lateral Movement Prevention solution that administers dynamic Just-in-Time (JIT) privileged account access to help customers achieve Zero Standing Privilege.',
      company: 'Remediant (now a part of Netwrix)',
      technologies: 'Python, NodeJS, AngularJS, MongoDB',
      industry: 'CyberSecurity',
      date: 'Nov. 2020 - Dec. 2022',
      url: {
        name: 'www.remediant.com',
        link: 'https://www.remediant.com/product-remediant-secureone',
      },
      thumbImage: 'images/projects/secure-one-1.webp',
      sliderImages: [],
      categories: [filters.CYBERSECURITY],
    },
    {
      title: 'MetaDefender Drive',
      projectInfo:
        'MetaDefender Drive is a secure firmware bootable USB used for assessing malware, vulnerabilities, and sensitive data in any USB bootable system. MetaDefender Drive uses the system’s inherent resources (CPU and RAM) to process the boot partitions and all files on the internal drives. It has also been optimized to lower its footprint for resource constrained devices often found in critical infrastructure like laptops as low as 1G RAM and 1 CPU.',
      company: 'OPSWAT',
      technologies: 'Linux, Electron, C++, ReactJS, SQLLite3',
      industry: 'CyberSecurity',
      date: 'Feb. 2019 - Nov. 2020',
      url: {
        name: 'www.opswat.com',
        link: 'https://www.opswat.com/products/metadefender/drive',
      },
      thumbImage: 'images/projects/metadefender-drive-1.webp',
      sliderImages: [
        'images/projects/metadefender-drive-2.webp',
        'images/projects/metadefender-drive-4.webp',
      ],
      categories: [filters.CYBERSECURITY],
    },
    {
      title: 'MetaDefender Kiosk',
      projectInfo:
        'OPSWAT MetaDefender Kiosk K3001 Premium is a turn-key solution for securing data transfer across sensitive network domains in critical infrastructure. It is specially tailored and ruggedized to withstand physical challenges in harsh operating environments while protecting your organizations digital data workflows.',
      company: 'OPSWAT',
      technologies: 'Electron, Angular, C++, SQLLite3',
      industry: 'CyberSecurity',
      date: 'Jan. 2018 - Nov. 2020',
      url: {
        name: 'www.opswat.com',
        link: 'https://www.opswat.com/products/metadefender/kiosk/k-series',
      },
      thumbImage: 'images/projects/metadefender-kiosk-1.webp',
      sliderImages: [
        'images/projects/metadefender-kiosk-2.webp',
        'images/projects/metadefender-kiosk-3.webp',
      ],
      categories: [filters.CYBERSECURITY],
    },
  ]

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope('.portfolio-filter', {
      itemSelector: '.filter-item',
      layoutMode: 'masonry',
    })

    // cleanup
    return () => {
      isotope.current.destroy()
    }
  }, [])

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === '*'
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` })
    }
  }, [filterKey, imagesLoaded])

  const handleFilterKeyChange = key => () => setFilterKey(key)

  return (
    <>
      <section
        id="portfolio"
        className={'section ' + (darkTheme ? 'bg-dark-2' : 'bg-light')}
      >
        <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                'text-24  text-uppercase fw-600 w-100 mb-0 ' +
                (darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4')
              }
            >
              Past Projects
            </h2>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              'portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 ' +
              (darkTheme ? 'nav-light' : '')
            }
          >
            <li className="nav-item">
              <button
                className={'nav-link ' + (filterKey === '*' ? 'active' : '')}
                onClick={handleFilterKeyChange('*')}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    'nav-link ' +
                    (filterKey === filters[oneKey] ? 'active' : '')
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      'col-sm-6 col-lg-4 filter-item ' +
                      project.categories.join(' ')
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1)
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index])
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  )
}

export default Portfolio
