import React from 'react'
import resumeFile from '../documents/FrankDyeResume-3-3-23.pdf'

function AboutUs({ classicHeader, darkTheme }) {
  return (
    <section id="about" className={`section ${darkTheme ? 'bg-dark-1' : ''}`}>
      <div className={`container ${classicHeader ? '' : 'px-lg-5'}`}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={`text-24  text-uppercase fw-600 w-100 mb-0 ${
              darkTheme ? 'text-muted opacity-1' : 'text-light opacity-4'
            }`}
          >
            About Me
          </h2>
        </div>
        {/* Heading end */}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={`text-7 fw-600 mb-3 ${darkTheme ? 'text-white' : ''}`}
            >
              I&apos;m <span className="text-primary">Frank Dye,</span> a
              seasoned engineering leader specializing in the cybersecurity
              ecosystem with expertise in Team Growth, Product Delivery, and
              Compliance.
            </h2>
            <p className={darkTheme ? 'text-white-50' : ''}>
              Responsible for implementing product strategy and managing
              development teams around the world. I play a key role in
              determining the direction of new technology, shaping its
              architecture, and ensuring its successful implementation. My
              primary areas of focus include enhancing engineering culture,
              attracting and retaining top talent, driving retention, and
              ensuring corporate compliance.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={`list-style-2 ${
                  darkTheme ? 'list-style-light text-light' : ''
                }`}
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Frank Dye
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:frank@frankdye.com">frank@frankdye.com</a>
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>San Francisco,
                  California
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download Resume
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={`brands-grid separator-border mt-5 ${
            darkTheme ? 'separator-border-light' : ''
          }`}
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={`text-12  mb-0 ${
                    darkTheme ? 'text-white-50' : 'text-muted'
                  }`}
                >
                  <span>8</span>+
                </h4>
                <p className={`mb-0 ${darkTheme ? 'text-light' : ''}`}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={`text-12  mb-0 ${
                    darkTheme ? 'text-white-50' : 'text-muted'
                  }`}
                >
                  <span>4</span>+
                </h4>
                <p className={`mb-0 ${darkTheme ? 'text-light' : ''}`}>
                  Years Senior Leadership
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={`text-12  mb-0 ${
                    darkTheme ? 'text-white-50' : 'text-muted'
                  }`}
                >
                  <span>20</span>
                </h4>
                <p className={`mb-0 ${darkTheme ? 'text-light' : ''}`}>
                  Avg. Team Size
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={`text-12  mb-0 ${
                    darkTheme ? 'text-white-50' : 'text-muted'
                  }`}
                >
                  <span>1</span>
                </h4>
                <p className={`mb-0 ${darkTheme ? 'text-light' : ''}`}>
                  Patents
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  )
}

export default AboutUs
